import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import { del, TOKEN_KEY } from '@src/utils/localStorage'
import { azureApiGatewayPost } from '@src/api/azureApiGateway/client'
import { setCookie } from '@src/utils/cookieUtil'
import { setToken } from '@src/api/azureApiGateway/client'
import useTranslations from '@src/hooks/useTranslations'
import ErrorModal from '@src/components/ErrorModal'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'

const SSO = ({ location }) => {
	const t = useTranslations()
	const [errorMessage, setErrorMessage] = useState(null)
	const [relayState, setRelayState] = useState('')
	const [destination, setDestination] = useState('')
	const [samlResponse, setSamlResponse] = useState('')
	const [samlForm, setSamlForm] = useState(false)

	const {
		doResetCustomer,
		isCustomerLoggedIn,
		customerInfo,
		doRefreshCustomerInfo,
	} = useConnect(
		'doResetCustomer',
		'selectIsCustomerLoggedIn',
		'selectCustomerInfo',
		'doRefreshCustomerInfo',
	)

	useEffect(() => {
		doRefreshCustomerInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		if (location.search === '') {
			navigate('/')
		}
		if (urlParams.get('action') === 'error') {
			setErrorMessage(t('apigw.error.sso'))
		}
		if (urlParams.get('action') === 'logout') {
			samlLogout()
			navigate('/')
		}
		if (urlParams.has('SAMLRequest')) {
			if (!isCustomerLoggedIn) {
				events.emit(EVENT_SHOW_LOGIN_MODAL, {})
			}
			if (isCustomerLoggedIn && customerInfo.customerId) {
				const SAMLRequest = urlParams.get('SAMLRequest')
				const RelayState = urlParams.get('RelayState')
				setRelayState(RelayState)
				saml(SAMLRequest, RelayState)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerInfo])

	async function samlLogout() {
		del(TOKEN_KEY)
		setCookie(TOKEN_KEY, '')
		setToken(null)
		await doResetCustomer()
	}

	async function saml(SAMLRequest, RelayState) {
		try {
			const response = await azureApiGatewayPost(
				`saml/${customerInfo.customerId}`,
				{
					saml_request: SAMLRequest,
					relay_state: RelayState,
				},
				{ useToken: true },
			)

			if (
				response.data &&
				response.data.destination &&
				response.data.saml_response &&
				response.messages.length === 0
			) {
				setDestination(response.data.destination)
				setSamlResponse(response.data.saml_response)
				setSamlForm(true)
				document.getElementById('samlForm').submit()
			} else if (response.messages.length > 0) {
				setErrorMessage(response.messages[0])
			}
		} catch (error) {
			setErrorMessage(error.message)
		}
	}

	return (
		<>
			{errorMessage && (
				<ErrorModal onClose={() => navigate('/')}>{errorMessage}</ErrorModal>
			)}
			{samlForm && (
				<form action={destination} method='post' id='samlForm'>
					<input type='hidden' name='SAMLResponse' value={samlResponse} />
					<input type='hidden' name='RelayState' value={relayState} />
				</form>
			)}
		</>
	)
}

export default SSO
