import './ErrorModal.scss'
import React from 'react'
import useTranslations from '@src/hooks/useTranslations'
import Button from '@material-ui/core/Button'
import BEMHelper from 'react-bem-helper'
import Modal from '@src/components/Modal'

const bem = new BEMHelper('error-modal')

export default function ErrorModal({
	onClose = () => {},
	buttonLabel,
	children,
}) {
	const t = useTranslations()
	const label = buttonLabel ? buttonLabel : t('kinto.general.try-again')

	if (children.length === 0) {
		children = 'kinto.error-modal.general'
	}

	return (
		<Modal open={true} handleClose={onClose} xButton bemClass={'blue'}>
			<div {...bem('')}>
				<div {...bem('info')}>
					<h4 {...bem('title')}>{t('kinto.error-modal.title')}</h4>
					{Array.isArray(children) ? (
						children.map((item, i) => {
							return item.startsWith('apigw') ? (
								<p key={i}>{t(item)}</p>
							) : (
								<p key={i}>{item}</p>
							)
						})
					) : children.startsWith('apigw') ? (
						<p>{t(children)}</p>
					) : (
						<p>{children}</p>
					)}
				</div>
				<div {...bem('button')}>
					<Button
						aria-label={label}
						variant='contained'
						color='secondary'
						onClick={onClose}
						fullWidth
					>
						{label}
					</Button>
				</div>
				<div {...bem('contact')}>
					<b {...bem('contact', 'title')}>{t('kinto.error-modal.contact')}</b>
					<div {...bem('contact', 'line')}>
						<p>{t('kinto.error-modal.call-us')}</p>
						<p>{t('kinto.error-modal.phonenumber')}</p>
					</div>
					<div {...bem('contact', 'line')}>
						<p>{t('kinto.error-modal.mail-us')}</p>
						<p>{t('kinto.error-modal.mail')}</p>
					</div>
				</div>
			</div>
		</Modal>
	)
}
